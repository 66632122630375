<template>
	<v-container>
		<v-row class="box-letter" v-if="load_menus">
			<v-col cols="6" sm="4" md="4" lg="4" class="pt-1" v-for="n in 4" :key="n">
				<v-sheet class="">
		      <v-responsive class="mx-auto">
		        <v-skeleton-loader
		          ref="skeleton"
		          type="card"
		          height="130"
		          class="mx-auto"
		        ></v-skeleton-loader>
		      </v-responsive>
		    </v-sheet>
			</v-col>
		</v-row>
		<!-- ITEMS CARTA -->
		<v-row class="box-letter" v-else>
			<v-col cols="6" sm="6" md="6" lg="4" class="pt-1" v-for="(menu, i) in menus" :key="i">
				<card-dishe-letter :item="menu" type="menu" />
			</v-col>
		</v-row>


	</v-container>
</template>
<script>

import CardDisheLetter from './components/CardDisheLetter'
	export default{
		name: 'letter',
		components: { CardDisheLetter },
		data(){
			return {
	      menus: [],
	      error: '',
	      load_menus: ''
			}
		},
		created(){
      this.getMenus();
    },
    methods:{
      async getMenus(){
      	this.load_menus = true;
        const url = this.$route.params.landing ? this.$route.params.landing : '';
        try{
          const {data} = await this.$api.get(`api/restaurant/${url}/menus`);
          let datos = data;
         	this.menus = datos.filter(menu => {
            return menu.visibility == 1? true : false;
          });
         	this.load_menus = false;
        }
        catch(e){
         this.error = e;
        }
      },
    },

	}
</script>
